import React from "react";
import ListingEntryType from "./table/ListingEntryType";
import ListingMenuBar from "./ListingMenuBar";
import ListingTopBar from "./ListingTopBar";
import ListingResponsiveTable from "./table/ListingResponsiveTable";
import ListingExpandModal from "./expand/ListingExpandModal";
import HeaderBar from "../HeaderBar";

type Props = {};

const data: ListingEntryType[] = [
  {
    id: 1,
    advisorName: "Imlimitado",
    numOfOrders: "105",
    completedPct: "96.40",
    interestRate: "2.10",
    available: "2,105.71",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
  {
    id: 2,
    advisorName: "Atlas48",
    numOfOrders: "249",
    completedPct: "96.40",
    interestRate: "2.05",
    available: "2,105.00",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
  {
    id: 3,
    advisorName: "kendil",
    numOfOrders: "543",
    completedPct: "96.40",
    interestRate: "1.95",
    available: "976.11",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
  {
    id: 4,
    advisorName: "NoName",
    numOfOrders: "12",
    completedPct: "76.40",
    interestRate: "1.87",
    available: "1,105.23",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
  {
    id: 5,
    advisorName: "Kkrps",
    numOfOrders: "234",
    completedPct: "99.40",
    interestRate: "1.78",
    available: "27,105.00",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
  {
    id: 6,
    advisorName: "Partsd",
    numOfOrders: "34",
    completedPct: "100",
    interestRate: "1.70",
    available: "30.10",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
  {
    id: 7,
    advisorName: "Ty",
    numOfOrders: "12",
    completedPct: "76.40",
    interestRate: "1.87",
    available: "1,105.23",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
  {
    id: 8,
    advisorName: "HelloWorld",
    numOfOrders: "234",
    completedPct: "99.40",
    interestRate: "1.78",
    available: "27,105.00",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
  {
    id: 9,
    advisorName: "Gfs",
    numOfOrders: "34",
    completedPct: "100",
    interestRate: "1.70",
    available: "30.10",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
  {
    id: 10,
    advisorName: "Tokm",
    numOfOrders: "543",
    completedPct: "96.40",
    interestRate: "1.95",
    available: "976.11",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
  {
    id: 11,
    advisorName: "Brosli_Traders22",
    numOfOrders: "12",
    completedPct: "76.40",
    interestRate: "1.87",
    available: "1,105.23",
    limit: "0-10 BTC",
    collateral: "BTC",
  },
];

const ListingPage: React.FC<Props> = (props: Props) => {
  return (
    <div className="flex flex-col">
      <HeaderBar title="Live Ads" />
      <ListingTopBar />
      <div className="lg:container lg:mx-auto px-6">
        <ListingMenuBar />
        <ListingResponsiveTable data={data} />
      </div>

      <ListingExpandModal entry={data[0]} />
    </div>
  );
};

export default ListingPage;
