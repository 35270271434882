import React from "react";
import DashboardDesktopTable from "./DashboardDesktopTable";
import DashboardEntryType from "./DashboardEntryType";
import DashboardMobileTable from "./DashboardMobileTable";

type Props = { data: DashboardEntryType[]; isLending: boolean };

const DashboardResponsiveTable: React.FC<Props> = ({
  data,
  isLending,
}: Props) => {
  return (
    <>
      <div className="md:hidden">
        <DashboardMobileTable data={data} isLending={isLending} />
      </div>
      <div className="hidden md:block">
        <DashboardDesktopTable data={data} isLending={isLending} />
      </div>
    </>
  );
};

export default DashboardResponsiveTable;
