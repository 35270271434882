import React from "react";
import { AiFillCaretDown, AiOutlineMenu } from "react-icons/ai";

type Props = { title?: string };

const HeaderBar: React.FC<Props> = ({ title }: Props) => {
  return (
    <div className="bg-primary">
      {/* More Top Header */}
      <div className="px-8 flex items-center justify-between text-sm py-4">
        <h2 className="text-primary-content text-lg font-bold">{title}</h2>

        <div className="flex flex-row justify-end">
          <div className="hidden md:flex md:flex-row">
            <a
              href="/"
              className="btn btn-sm btn-ghost flex flex-row gap-1 text-primary-content"
            >
              Live Ads
            </a>
            <a
              href="/post-ad"
              className="btn btn-sm btn-ghost flex flex-row gap-1 text-primary-content"
            >
              Post Ad
            </a>
            <a
              href="/dashboard"
              className="btn btn-sm btn-ghost flex flex-row gap-1 text-primary-content"
            >
              Dashboard
            </a>
          </div>

          <div className="dropdown md:hidden">
            <label tabIndex={0}>
              <div className="btn btn-sm btn-ghost flex flex-row gap-1 text-primary-content">
                <AiOutlineMenu />
                More
                <AiFillCaretDown />
              </div>
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu shadow bg-base-100 rounded-box w-32"
            >
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/post-ad">Post Ad</a>
              </li>
              <li>
                <a href="/dashboard">Dashboard</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
