import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import DashboardPage from "./components/dashboard/DashboardPage";
import ListingPage from "./components/listing/ListingPage";
import PostLendingAdPage from "./components/post-ad/PostLendingAdPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ListingPage />,
  },
  {
    path: "/post-ad",
    element: <PostLendingAdPage />,
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
]);

function App() {
  return (
    <div className="flex flex-col">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
