import React from "react";

const DashboardTopBar: React.FC = () => {
  return (
    <div className="flex shadow-lg p-2 px-8 gap-8">
      <div>
        <span className="font-bold text-accent">User: </span>
        <span>akd44</span>
      </div>
      <div>
        <span className="font-bold text-accent">Total Lending: </span>
        <span>$5050.00</span>
      </div>
      <div>
        <span className="font-bold text-accent">Total Borrowing: </span>
        <span>$1200.00</span>
      </div>
    </div>
  );
};

export default DashboardTopBar;
