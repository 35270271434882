const DashboardMenuBar: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row gap-4 mt-4 p-2 justify-between">
      <div className="flex flex-col md:flex-row gap-4">
        {/* Coins */}
        <div className="form-control">
          <label className="label">
            <span className="label-text text-neutral">Coins</span>
          </label>
          <select className="select select-bordered" defaultValue="All Coins">
            <option>All Coins</option>
            <option>USDT</option>
            <option>ABCD</option>
            <option>XYZ</option>
          </select>
        </div>

        {/* Status */}
        <div className="form-control">
          <label className="label">
            <span className="label-text text-neutral">Status</span>
          </label>
          <select className="select select-bordered" defaultValue="All Status">
            <option>All Status</option>
            <option>Status A</option>
            <option>Status B</option>
          </select>
        </div>

        {/* Date Range */}
        <div className="form-control">
          <label className="label">
            <span className="label-text text-neutral">Date Range</span>
          </label>
          <div className="flex md:w-full md:max-w-xs">
            <input
              type="date"
              placeholder="From Date"
              className="input input-bordered rounded-r-none w-1/2"
            />
            <input
              type="date"
              placeholder="To Date"
              className="input input-bordered rounded-l-none w-1/2"
            />
          </div>
        </div>

        {/* Filter Button */}
        <div className="mt-4 md:mt-auto">
          <button className="btn btn-accent btn-outline text-accent gap-1 w-full md:w-32">
            Reset Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardMenuBar;
