import React from "react";
import ListingEntryType from "./ListingEntryType";

type Props = { data: ListingEntryType[] };

const ListingDesktopTable: React.FC<Props> = ({ data }: Props) => {
  return (
    <>
      <div className="overflow-x-auto mt-5">
        <table className="table table-compact w-full">
          {/* head */}
          <tbody>
            <tr className="text-neutral">
              <td className="text-left normal-case text-xs">
                Advertisers (Completion rate)
              </td>
              <td className="text-left normal-case text-xs">
                Interest rate{" "}
                <span className="badge badge-accent badge-outline text-xs">
                  lowest to highest
                </span>
              </td>
              <td className="text-left normal-case text-xs">Limit/Available</td>
              <td className="text-left normal-case text-xs">Collateral</td>
              <td className="text-right normal-case text-xs">
                Trade{" "}
                <span className="badge badge-success badge-outline text-xs">
                  0 Fee
                </span>
              </td>
            </tr>
            {/* row */}
            {data.map((val) => (
              <tr key={val.id}>
                <td className="flex py-4">
                  <div className="flex items-start gap-4 ">
                    <div className="avatar placeholder">
                      <div className="bg-blue-800 text-primary-content rounded-full w-8">
                        <span className="text-sm">{val.advisorName[0]}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex text-blue-800 font-medium">
                        {val.advisorName}
                      </div>
                      <div className="flex">
                        <span className="text-xs w-20">
                          {val.numOfOrders} orders
                        </span>
                        <span className="text-xs hidden">
                          {val.completedPct}% completed
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-2xl font-bold">{val.interestRate}</span>{" "}
                  %
                </td>
                <td>
                  <div className="flex gap-4">
                    <span className="text-neutral text-xs w-14">
                      Available{" "}
                    </span>
                    <span className="font-medium">{val.available} USDT</span>
                  </div>
                  <div className="flex gap-4">
                    <span className="text-neutral text-xs w-14">Limit</span>
                    <span className="font-medium">{val.limit}</span>
                  </div>
                </td>
                <td>
                  <span className="badge badge-info badge-outline text-xs">
                    {val.collateral}
                  </span>
                </td>
                <td className="text-right">
                  <label
                    htmlFor="listing-expand-modal"
                    className="btn btn-success btn-sm gap-1"
                  >
                    Borrow USDT
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListingDesktopTable;
