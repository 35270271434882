import React from "react";
import HeaderBar from "../HeaderBar";
import DashboardContent from "./DashboardContent";
import DashboardEntryType from "./table/DashboardEntryType";
import DashboardTopBar from "./DashboardTopBar";

type Props = {};

const lendingData: DashboardEntryType[] = [
  {
    id: 1,
    lentTo: "Lkohhy",
    principle: "200.00 USDT",
    interest: "0.52 USDT",
    collateral: "0.198 ETH",
    liquidationPrice: "1563.02 USDT",
    status: "Open",
    date: "2023-03-24 18:25:33",
  },
  {
    id: 2,
    lentTo: "Gibson23",
    principle: "500.00 USDT",
    interest: "1.75 USDT",
    collateral: "0.08 BTC",
    liquidationPrice: "2200.45 USDT",
    status: "Open",
    date: "2023-03-27 10:12:47",
  },
  {
    id: 3,
    lentTo: "CryptoWizard",
    principle: "1000.00 USDT",
    interest: "3.25 USDT",
    collateral: "2.52 ETH",
    liquidationPrice: "3500.22 USDT",
    status: "Completed",
    date: "2023-03-28 16:43:12",
  },
  {
    id: 4,
    lentTo: "SatoshiFan",
    principle: "2500.00 USDT",
    interest: "12.15 USDT",
    collateral: "0.5 BTC",
    liquidationPrice: "8000.00 USDT",
    status: "Open",
    date: "2023-03-29 21:09:59",
  },
];

const borrowingData: DashboardEntryType[] = [
  {
    id: 5,
    lentTo: "Seth89",
    principle: "300.00 USDT",
    interest: "0.78 USDT",
    collateral: "0.301 ETH",
    liquidationPrice: "2257.55 USDT",
    status: "Open",
    date: "2023-03-25 09:12:14",
  },
  {
    id: 6,
    lentTo: "Kyla12",
    principle: "150.00 USDT",
    interest: "0.39 USDT",
    collateral: "0.151 ETH",
    liquidationPrice: "1187.23 USDT",
    status: "Completed",
    date: "2023-03-26 15:08:47",
  },
  {
    id: 7,
    lentTo: "Brent33",
    principle: "500.00 USDT",
    interest: "1.30 USDT",
    collateral: "0.502 ETH",
    liquidationPrice: "3795.00 USDT",
    status: "Open",
    date: "2023-03-27 21:36:22",
  },
  {
    id: 8,
    lentTo: "Cameron92",
    principle: "1000.00 USDT",
    interest: "2.60 USDT",
    collateral: "1.005 ETH",
    liquidationPrice: "7590.00 USDT",
    status: "Open",
    date: "2023-03-28 16:45:59",
  },
  {
    id: 9,
    lentTo: "Nadine55",
    principle: "750.00 USDT",
    interest: "1.95 USDT",
    collateral: "0.753 ETH",
    liquidationPrice: "5692.50 USDT",
    status: "Completed",
    date: "2023-03-29 08:27:11",
  },
  {
    id: 10,
    lentTo: "Adam44",
    principle: "250.00 USDT",
    interest: "0.65 USDT",
    collateral: "0.251 ETH",
    liquidationPrice: "1897.50 USDT",
    status: "Open",
    date: "2023-03-29 14:52:39",
  },
  {
    id: 11,
    lentTo: "Olivia07",
    principle: "400.00 USDT",
    interest: "1.04 USDT",
    collateral: "0.402 ETH",
    liquidationPrice: "3036.00 USDT",
    status: "Open",
    date: "2023-03-29 19:08:56",
  },
  {
    id: 12,
    lentTo: "Elijah29",
    principle: "100.00 USDT",
    interest: "0.26 USDT",
    collateral: "0.101 ETH",
    liquidationPrice: "757.00 USDT",
    status: "Completed",
    date: "2023-03-30 10:14:07",
  },
  {
    id: 13,
    lentTo: "Jenna88",
    principle: "800.00 USDT",
    interest: "2.08 USDT",
    collateral: "0.804 ETH",
    liquidationPrice: "6072.00 USDT",
    status: "Open",
    date: "2023-03-30 17:49:23",
  },
];

const DashboardPage: React.FC<Props> = (props: Props) => {
  return (
    <div className="flex flex-col">
      <HeaderBar title="Dashboard" />
      <DashboardTopBar />
      <div className="lg:container lg:mx-auto px-6">
        <DashboardContent
          lendingData={lendingData}
          borrowingData={borrowingData}
        />
      </div>
    </div>
  );
};

export default DashboardPage;
