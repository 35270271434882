import React from "react";
import DashboardEntryType from "./DashboardEntryType";

type Props = { data: DashboardEntryType[]; isLending: boolean };

const DashboardMobileTable: React.FC<Props> = ({ data, isLending }: Props) => {
  return (
    <div className="mt-5">
      {data.map((val) => (
        <div key={val.id}>
          <div key={val.id}>
            {/* Row */}
            <div className="flex flex-row justify-between p-2">
              {/* Avatar and Name */}
              <div className="flex gap-4">
                <div className="avatar placeholder">
                  <div className="bg-blue-800 text-primary-content rounded-full w-8">
                    <span className="text-sm">{val.lentTo[0]}</span>
                  </div>
                </div>
                <div className="text-blue-800 font-medium my-auto">
                  {val.lentTo}
                </div>
              </div>

              <div className="text-end flex flex-col justify-between">
                {/* Button */}
                <button className="btn btn-success btn-sm gap-1">View</button>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto px-4 mb-2">
            <div className="w-full">
              <div className="flex flex-row justify-between">
                <div className="text-left text-xs text-neutral">Principle</div>
                <div className="text-right text-xs">{val.principle}</div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-left text-xs text-neutral">Interest</div>
                <div className="text-right text-xs">{val.interest}</div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-left text-xs text-neutral">Collateral</div>
                <div className="text-right text-xs">{val.collateral}</div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-left text-xs text-neutral">
                  Liquidation Price
                </div>
                <div className="text-right text-xs">{val.liquidationPrice}</div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-left text-xs text-neutral">Status</div>
                <div className="text-right text-xs">{val.status}</div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-left text-xs text-neutral">Date</div>
                <div className="text-right text-xs">{val.date}</div>
              </div>
            </div>
          </div>

          <hr />
        </div>
      ))}
    </div>
  );
};

export default DashboardMobileTable;
