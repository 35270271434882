import React from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import HeaderBar from "../HeaderBar";

type Props = {};

const PostLendingAdPage: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <HeaderBar title="Post Ad" />

      <div className="container mx-auto p-2">
        <div className="w-full max-w-3xl mx-auto">
          <div className="card bg-base-100 shadow-xl my-3">
            <div className="card-body">
              <h2 className="card-title pb-4">Post Lending Ad</h2>

              <div className="sm:flex sm:flex-row gap-4">
                {/* Asset */}
                <div className="form-control sm:w-2/5">
                  <label className="label">
                    <span className="label-text text-neutral">Asset</span>
                  </label>
                  <select className="select select-bordered" defaultValue="BTC">
                    <option>BTC</option>
                    <option>ABCD</option>
                    <option>XYZ</option>
                  </select>
                </div>

                {/* Collateral */}
                <div className="form-control sm:w-2/5">
                  <label className="label">
                    <span className="label-text text-neutral whitespace-nowrap">
                      With Collateral
                    </span>
                  </label>
                  <select className="select select-bordered" defaultValue="ETH">
                    <option>BTC</option>
                    <option>ETH</option>
                    <option>XYZ</option>
                  </select>
                </div>
              </div>

              <div className="sm:flex sm:flex-row gap-4">
                {/* Your Interest */}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text text-neutral whitespace-nowrap">
                      Your Interest
                    </span>
                  </label>
                  <div className="flex flex-row gap-2">
                    <div>
                      <button className="btn btn-secondary">-</button>
                    </div>
                    <div className="form-control w-3/5 sm:w-fit">
                      <input
                        type="text"
                        defaultValue="2.10"
                        className="input input-bordered text-center"
                      />
                    </div>
                    <div>
                      <button className="btn btn-secondary">+</button>
                    </div>
                  </div>
                </div>

                {/* Lowest Order Interest */}
                <div className="form-control sm:w-1/4">
                  <label className="label">
                    <span className="label-text text-neutral whitespace-nowrap">
                      Lowest Order Interest
                    </span>
                  </label>
                  <input
                    type="text"
                    defaultValue="2.30%"
                    className="input input-bordered"
                    readOnly
                    disabled
                  />
                </div>
              </div>

              <hr className="my-5" />

              <div className="form-control sm:w-4/5">
                <label className="label">
                  <span className="label-text text-neutral whitespace-nowrap">
                    Total Amount
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="0"
                  className="input input-bordered"
                />
                <label className="label">
                  <span className="label-text text-xs text-neutral whitespace-nowrap">
                    Available:{" "}
                    <span className="font-medium text-primary">5.87689</span>{" "}
                    USDT
                    <button className="btn text-accent btn-link btn-xs">
                      All
                    </button>
                  </span>
                </label>
              </div>

              {/* Order Limit */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-neutral">Order Limit</span>
                </label>
                <div className="sm:flex sm:flex-row gap-4">
                  <input
                    type="text"
                    placeholder="0 BTC"
                    className="input input-bordered w-full"
                  />

                  <span className="my-auto hidden sm:inline-block">~</span>
                  <input
                    type="text"
                    placeholder="5 BTC"
                    className="input input-bordered w-full mt-2 sm:mt-0"
                  />
                </div>
              </div>

              {/* Expiration Time */}
              <div className="form-control sm:w-3/5">
                <label className="label">
                  <span className="label-text text-neutral">
                    Expiration Time
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="300 days"
                  className="input input-bordered"
                />
              </div>
            </div>
          </div>
          <div className="card-actions justify-between">
            <button className="btn btn-secondary btn-xs gap-1">
              <AiFillQuestionCircle className="text-accent" />
              Help & Guidance
            </button>
            <button className="btn btn-accent">Publish</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostLendingAdPage;
