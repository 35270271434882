import React from "react";
import ListingEntryType from "./ListingEntryType";

type Props = { data: ListingEntryType[] };

const ListingMobileTable: React.FC<Props> = ({ data }: Props) => {
  return (
    <div className="mt-5">
      {data.map((val) => (
        <div key={val.id}>
          {/* Row */}
          <div className="flex flex-row justify-between p-2">
            <div>
              {/* Avatar and Name */}
              <div className="flex flex-row items-start gap-4 pb-3">
                <div className="avatar placeholder">
                  <div className="bg-blue-800 text-primary-content rounded-full w-8">
                    <span className="text-sm">{val.advisorName[0]}</span>
                  </div>
                </div>
                <div>
                  <div className="flex text-blue-800 font-medium">
                    {val.advisorName}
                  </div>
                  <div className="flex">
                    <span className="text-xs w-20">
                      {val.numOfOrders} orders
                    </span>
                    <span className="text-xs hidden">
                      {val.completedPct}% completed
                    </span>
                  </div>
                </div>
              </div>

              {/* Available */}
              <div className="flex">
                <span className="text-neutral text-xs w-14">Available </span>
                <span className="font-medium text-xs">
                  {val.available} USDT
                </span>
              </div>

              {/* Limit */}
              <div className="flex">
                <span className="text-neutral text-xs w-14">Limit</span>
                <span className="font-medium text-xs">{val.limit}</span>
              </div>
            </div>

            <div className="text-end flex flex-col justify-between gap-1">
              {/* Interest Rate */}
              <div>
                <div className="text-neutral text-xs">Interest Rate</div>
                <span className="text-xl font-bold">{val.interestRate}</span> %
              </div>
              {/* Payment Option */}
              <span className="badge badge-info badge-outline text-xs ml-auto">
                {val.collateral} Collateral
              </span>
              {/* Button */}
              <label
                htmlFor="listing-expand-modal"
                className="btn btn-success btn-sm gap-1"
              >
                Borrow USDT
              </label>
            </div>
          </div>

          <hr />
        </div>
      ))}
    </div>
  );
};

export default ListingMobileTable;
