import React from "react";
import DashboardEntryType from "./DashboardEntryType";

type Props = { data: DashboardEntryType[]; isLending: boolean };

const DashboardDesktopTable: React.FC<Props> = ({ data, isLending }: Props) => {
  return (
    <>
      <div className="overflow-x-auto mt-5">
        <table className="table table-compact w-full">
          {/* head */}
          <tbody>
            <tr className="text-neutral">
              <td className="text-left normal-case text-xs">Lent To</td>
              <td className="text-left normal-case text-xs">Principle</td>
              <td className="text-left normal-case text-xs">Interest</td>
              <td className="text-left normal-case text-xs">Collateral</td>
              <td className="text-left normal-case text-xs">
                Liquidation Price
              </td>
              <td className="text-left normal-case text-xs">Status</td>
              <td className="text-left normal-case text-xs">Date</td>
              <td className="text-right normal-case text-xs">Action</td>
            </tr>
            {/* row */}
            {data.map((val) => (
              <tr key={val.id}>
                <td className="flex py-4">
                  <div className="flex gap-4 ">
                    <div className="avatar placeholder">
                      <div className="bg-blue-800 text-primary-content rounded-full w-8">
                        <span className="text-sm">{val.lentTo[0]}</span>
                      </div>
                    </div>
                    <div className="text-blue-800 font-medium my-auto">
                      {val.lentTo}
                    </div>
                  </div>
                </td>
                <td>
                  <span>{val.principle}</span>
                </td>
                <td>
                  <span className="font-bold">{val.interest}</span>
                </td>
                <td>
                  <span>{val.collateral}</span>
                </td>
                <td>
                  <span
                    className={`${isLending ? "font-bold text-error" : ""}`}
                  >
                    {val.liquidationPrice}
                  </span>
                </td>
                <td>
                  <span className="font-bold text-success">{val.status}</span>
                </td>
                <td>
                  <span>{val.date}</span>
                </td>
                <td className="text-right">
                  <button className="btn btn-success btn-sm gap-1">View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DashboardDesktopTable;
