import React from "react";
import ListingEntryType from "../table/ListingEntryType";

type Props = { entry: ListingEntryType };

const ListingExpandModal: React.FC<Props> = ({ entry }: Props) => {
  return (
    <>
      <input
        type="checkbox"
        id="listing-expand-modal"
        className="modal-toggle"
      />
      <div className="modal modal-bottom md:modal-middle">
        <div className="modal-box md:w-11/12 md:max-w-5xl">
          <h3 className="font-bold text-lg">Borrow USDT</h3>

          <div className="flex pr-2 flex-col md:flex-row">
            <div className="md:w-1/4  pb-2 md:pb-0">
              {/* Name */}
              <div className="flex flex-row items-start gap-4 py-3">
                <div className="avatar placeholder">
                  <div className="bg-blue-800 text-primary-content rounded-full w-8">
                    <span>{entry.advisorName[0]}</span>
                  </div>
                </div>
                <div>
                  <div className="flex text-blue-800 font-medium">
                    {entry.advisorName}
                  </div>
                  <div className="text-xs">{entry.numOfOrders} orders</div>
                  <div className="text-xs hidden had-flex-before-instead-of-hidden">
                    {entry.completedPct}% completed
                  </div>
                </div>
              </div>

              {/* Available */}
              <div className="flex">
                <span className="text-neutral text-xs my-auto w-28">
                  Interest{" "}
                </span>
                <span className="font-medium">{entry.interestRate}%</span>
              </div>

              {/* Limit */}
              <div className="flex">
                <span className="text-neutral text-xs my-auto w-28">
                  Available
                </span>
                <span className="font-medium">{entry.available} USDT</span>
              </div>

              {/* Limit */}
              <div className="flex">
                <span className="text-neutral text-xs my-auto w-28">
                  Borrowable Limit
                </span>
                <span className="font-medium">80%</span>
              </div>

              {/* Limit */}
              <div className="flex">
                <span className="text-neutral text-xs my-auto w-28">
                  Collateral
                </span>
                <span className="font-medium">{entry.collateral}</span>
              </div>
            </div>

            <div className="md:w-3/4 pt-2 md:pt-0 md:pl-2 md:border-l border-t md:border-t-0">
              {/* Collateral Amount Text Box */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-neutral">
                    Collateral Amount
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="0 - 10 BTC"
                  className="input input-bordered w-full"
                />
              </div>

              {/* I Will Receive Text Box */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-neutral">
                    I will receive
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="0 - 280000.00 USDT"
                  className="input input-bordered w-full"
                />
              </div>

              <div className="modal-action pl-2">
                <label htmlFor="listing-expand-modal" className="btn w-2/5">
                  Cancel
                </label>
                <label
                  htmlFor="listing-expand-modal"
                  className="btn btn-success w-3/5"
                >
                  Borrow
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingExpandModal;
