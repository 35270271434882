import React from "react";
import { AiOutlineDown, AiOutlineQuestionCircle } from "react-icons/ai";

const ListingTopBar: React.FC = () => {
  return (
    <div className="flex shadow-lg p-2 px-8 justify-between md:justify-normal">
      <div className="tabs w-1/2">
        <span className="tab tab-bordered text-accent border-accent">USDT</span>
      </div>

      {/* User Guide DropDown */}
      <div className="dropdown">
        <label tabIndex={0}>
          <div className="btn btn-sm btn-ghost flex btn-secondary flex-row gap-1">
            <AiOutlineQuestionCircle />
            User Guide
            <AiOutlineDown />
          </div>
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu shadow bg-base-100 rounded-box w-40"
        >
          <li>
            <a href="/">Item 1</a>
          </li>
          <li>
            <a href="/">Item 2</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ListingTopBar;
