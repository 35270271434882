import { AiOutlineDown, AiOutlineSync } from "react-icons/ai";

const ListingMenuBar: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row gap-4 mt-4 p-2 justify-between">
      <div className="flex flex-col md:flex-row gap-4">
        {/* Amount */}
        <div className="form-control">
          <label className="label">
            <span className="label-text text-neutral">Amount</span>
          </label>
          <input
            type="text"
            placeholder="Enter Amount"
            className="input input-bordered md:w-full md:max-w-xs"
          />
        </div>

        {/* Borrow */}
        <div className="form-control">
          <label className="label">
            <span className="label-text text-neutral">Borrow</span>
          </label>
          <select className="select select-bordered" defaultValue="USDT">
            <option>USDT</option>
            <option>ABCD</option>
            <option>XYZ</option>
          </select>
        </div>

        {/* Collateral */}
        <div className="form-control">
          <label className="label">
            <span className="label-text text-neutral">Collateral</span>
          </label>
          <select className="select select-bordered" defaultValue="BTC">
            <option>BTC</option>
            <option>ABCD</option>
            <option>XYZ</option>
          </select>
        </div>

        {/* Liquidation Ratio */}
        <div className="form-control">
          <label className="label">
            <span className="label-text text-neutral whitespace-nowrap">
              Liquidation Ratio
            </span>
          </label>
          <select className="select select-bordered" defaultValue="80%">
            <option>80%</option>
            <option>50%</option>
            <option>20%</option>
          </select>
        </div>

        {/* Filter Button */}
        <div className="mt-4 md:mt-auto">
          <button className="btn btn-accent btn-outline text-accent gap-1 w-full md:w-28">
            Filter
            <AiOutlineDown />
          </button>
        </div>
      </div>

      <div className="mt-4 md:mt-auto">
        <button className="btn btn-secondary gap-1 w-full md:w-32">
          <AiOutlineSync />
          Refresh
        </button>
      </div>
    </div>
  );
};

export default ListingMenuBar;
