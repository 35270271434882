import React, { useState } from "react";
import DashboardEntryType from "./table/DashboardEntryType";
import DashboardMenuBar from "./DashboardMenuBar";
import DashboardResponsiveTable from "./table/DashboardResponsiveTable";

type Props = {
  lendingData: DashboardEntryType[];
  borrowingData: DashboardEntryType[];
};

const DashboardContent: React.FC<Props> = ({
  lendingData,
  borrowingData,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>
      <div className="tabs w-full border-b mt-10 ">
        {["Lending", "Borrowing"].map((value, index) => (
          <span
            key={value}
            className={`tab tab-bordered ${
              selectedTab === index ? "tab-active" : ""
            }`}
            onClick={() => setSelectedTab(index)}
          >
            {value}
          </span>
        ))}
      </div>
      <DashboardMenuBar />
      <DashboardResponsiveTable
        data={[lendingData, borrowingData][selectedTab]}
        isLending={selectedTab === 0}
      />
    </div>
  );
};

export default DashboardContent;
