import React from "react";
import ListingMobileTable from "./ListingMobileTable";
import ListingEntryType from "./ListingEntryType";
import ListingDesktopTable from "./ListingDesktopTable";

type Props = { data: ListingEntryType[] };

const ListingResponsiveTable: React.FC<Props> = ({ data }: Props) => {
  return (
    <>
      <div className="md:hidden">
        <ListingMobileTable data={data} />
      </div>
      <div className="hidden md:block">
        <ListingDesktopTable data={data} />
      </div>
    </>
  );
};

export default ListingResponsiveTable;
